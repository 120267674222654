<template>
  <div class="test-item">
    <div class="test__description">
      <slot name="description"></slot>
    </div>

    <div class="test__content">
      <div class="test__progress">
        <div
            class="test__progress-inner"
            :style="{
            width: `${
              getStageBar *
              (100 / (getAnswers.q0_1 && getAnswers.q0_1.includes(1) ? 9 : 8))
            }%`,
          }"
        >
          <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M7.86094 10.5224L5.76358 12.6182C4.67893 13.6807 6.23514 14.3357 4.64286 15.8957L4.50979 16.0192C4.37105 16.1551 4.37105 16.3806 4.51294 16.5165C4.64852 16.6524 4.87871 16.6555 5.02058 16.5165L5.15049 16.3991C6.70494 14.8732 7.41646 16.1056 8.46327 15.077L13.0673 10.5224C10.9303 9.78737 9.65166 11.4413 7.86094 10.5224Z"
                fill="white"
            />
            <path
                d="M11.6738 3.2539C11.1306 2.71068 10.2824 2.67815 9.77929 3.18128C9.27614 3.68451 9.30859 4.53266 9.85176 5.07588L10.1796 5.4038L4.09509 11.4853C3.38206 12.1979 3.00924 13.1814 3.07291 14.19C3.11404 15.0886 2.78887 15.8535 2.22565 16.4168L1.48746 17.155C0.837514 17.8051 0.837514 18.8625 1.48746 19.5126C2.13772 20.1628 3.19597 20.1621 3.8451 19.5126L4.57994 18.7779C6.18667 17.1701 7.69592 18.7313 9.51809 16.9081L15.5993 10.8237L15.9247 11.1491C16.4679 11.6923 17.3161 11.7248 17.8192 11.2216C18.3223 10.7185 18.2899 9.87032 17.7467 9.32709L11.6738 3.2539ZM9.09122 16.4812C7.45337 18.1197 6.0095 16.4946 4.15307 18.3511L3.41823 19.0857C3.00354 19.5 2.32903 19.5 1.91434 19.0857C1.49984 18.671 1.49984 17.9965 1.91434 17.5818L2.65252 16.8436C3.33077 16.1654 3.72841 15.2384 3.6751 14.1456C3.62282 13.3166 3.93138 12.5026 4.52197 11.9122L10.6036 5.83373L15.1695 10.3996L9.09122 16.4812Z"
                fill="black"
                stroke="black"
                stroke-width="0.5"
            />
            <path
                d="M19.9688 1.0324C18.5882 -0.344133 16.3502 -0.344133 14.9737 1.0324L12.6392 3.36695L17.6342 8.36199L19.9688 6.03152C21.3494 4.65091 21.3494 2.41302 19.9688 1.0324Z"
                fill="black"
            />
          </svg>
        </div>
      </div>

      <div class="test__form">
        <slot name="test"></slot>

        <div class="test__buttons">
          <button class="test__prev" v-if="getStage > 0" @click="goBack()">
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M16.513 12.8596L10.8648 12.3692L10.8655 14.9149C10.8655 15.4025 10.3441 15.7174 9.91104 15.4848L0.609445 10.4688C0.156381 10.2262 0.156144 9.5729 0.609431 9.33018L9.91091 4.31446C10.3405 4.08182 10.8653 4.39481 10.8653 4.88438L10.8648 7.43006L16.5129 6.93975C16.8854 6.90783 17.2161 7.19954 17.2161 7.58462L17.2162 12.2148C17.2162 12.5919 16.8924 12.8921 16.513 12.8596Z"
                  fill="#ED0000"
              />
            </svg>
            Назад
          </button>
          <button
              class="test__next"
              @click="goNext()"
              :disabled="!getIsStageEnable"
          >
            <p>Далі</p>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M3.28599 12.8586L8.9341 12.3682L8.9335 14.9138C8.93349 15.4015 9.45487 15.7164 9.88791 15.4837L19.1895 10.4678C19.6426 10.2252 19.6428 9.57184 19.1895 9.32912L9.88804 4.31341C9.45844 4.08076 8.93363 4.39375 8.93362 4.88332L8.93416 7.429L3.28607 6.9387C2.91357 6.90677 2.58284 7.19848 2.58284 7.58356L2.58278 12.2137C2.58272 12.5909 2.90657 12.891 3.28599 12.8586Z"
                  fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "TestItem",
  data: () => ({
    maxStage: 1,
  }),
  computed: {
    ...mapGetters([
      "getStage",
      "getStageBar",
      "getAnswers",
      "getIsStageEnable",
      "getResult",
    ]),
  },
  methods: {
    ...mapMutations([
      "setStage",
      "setStageBar",
      "setAnswers",
      "setIsStageEnable",
    ]),
    ...mapActions(["setResult"]),
    goNext() {
      window.scrollTo({
        top: 0,
      });
      if (this.getIsStageEnable) {
        this.setResult();
        if (this.getResult) {
          this.$router.push('/result')
        } else {
          this.setStage(this.getStage + 1);
          this.setStageBar(this.getStageBar + 1);

          if (this.getStage > this.maxStage) {
            this.maxStage = this.getStage;
          }
          if (this.getStage < this.maxStage) {
            this.setIsStageEnable(true);
          } else {
            this.setIsStageEnable(false);
          }

        }
      }
    },
    goBack() {
      this.setStage(this.getStage - 1);
      this.setStageBar(this.getStageBar - 1);
      this.setIsStageEnable(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.test {

  &-item {
    height: 100%;

    @media screen and (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__description {
    @media screen and (min-width: 1200px) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      min-height: 100%;
      width: 100%;
      //min-width: 600px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      min-height: 100%;
      width: 100%;
      //min-width: 535px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &__item--0 {
    .test__description {
      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-1.svg);
        background-size: 95%;
        background-position: 10px center;
      }
    }
  }

  &__item--1 {
    .test__description {
      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-2.svg);
        background-size: 100%;
        background-position: center center;
        margin-top: 8%;
      }
    }
  }

  &__item--2 {
    .test__description {
      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-3.svg);
        background-size: 100%;
        background-position: 40px center;
      }
    }

    .test__label {
      @media screen and (min-width: 768px) {
        max-width: 50%;
      }
    }
  }

  &__item--3 {
    .test__description {

      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-4.svg);
        background-size: 100%;
        background-position: 70px center;
        margin-top: 8%;
      }

      //@media screen and (max-height: 1189px) and (min-width: 1200px) {
      //  background-image: url(../assets/img/test-4.svg);
      //}
    }

    .test__form-container {
      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }

    .test__label {
      border-radius: 8px;

      height: 100%;

      &::before {
        border-radius: 0;
      }
    }

    .test__input:checked + .test__label::before {
      background-image: url(../assets/img/checked.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      background-color: #c83b26;

      border: 2px solid;
    }
  }

  &__item--3-1 {
    .test__description {
      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-3_1.svg);
        background-size: 90%;
        background-position: 30px center;
        margin-top: -4%;
      }
      //@media screen and (max-height: 1189px) and (min-width: 1200px) {
      //  background-image: url(../assets/img/test-3_1.svg);
      //}
    }

    .test__label {
      @media screen and (min-width: 768px) {
        max-width: 50%;
      }
    }
  }

  &__item--4 {
    .test__description {

      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-5.svg);
      }

      //@media screen and (max-height: 1189px) and (min-width: 1200px) {
      //  background-image: url(../assets/img/test-5.svg);
      //}
    }

    .test__label {
      align-items: flex-start;

      border-radius: 8px;

      height: 100%;

      &::before {
        border-radius: 0;
      }
    }

    .test__input:checked + .test__label::before {
      background-image: url(../assets/img/checked.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      background-color: #c83b26;

      border: 2px solid;
    }
  }

  &__item--5 {
    .test__description {
      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-6.svg);
        background-size: 95%;
        background-position: 80px center;
        margin-top: 7%;
      }

      //@media screen and (max-height: 1189px) and (min-width: 1200px) {
      //  background-image: url(../assets/img/test-6.svg);
      //}
    }
  }

  &__item--6 {
    .test__description {
      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-7.svg);
        background-size: 110%;
        background-position: 50px center;
        margin-top: 7%;
      }
    }

    .test__label {
      @media screen and (min-width: 768px) {
        max-width: 50%;
      }
    }
  }

  &__item--7 {
    &.women {
      .test__description {
        @media screen and (min-width: 1200px) {
          background-image: url(../assets/img/test-8.svg);
          background-size: 110%;
          background-position: 40px center;
        }
        //@media screen and (max-height: 1189px) and (min-width: 1200px) {
        //  background-image: url(../assets/img/test-8.svg);
        //}
      }
    }

    .test__description {
      @media screen and (min-width: 1200px) {
        background-image: url(../assets/img/test-8-w.svg);
        background-size: 110%;
        background-position: 40px center;
      }
    }

    .test__label {
      @media screen and (min-width: 768px) {
        max-width: 50%;
      }
    }
  }

  &__content {
    margin-top: 35px;

    @media screen and (min-width: 768px) {
      margin-top: 57px;
    }

    @media screen and (min-width: 1200px) {
      width: 90%;
    }
  }

  &__progress {
    position: relative;

    width: 50%;
    height: 1px;

    background-color: #000;

    border-radius: 20px;

    margin-left: auto;

    &-inner {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-25%);

      height: 2px;

      background-color: #fff;

      border-radius: 20px;

      svg {
        position: absolute;
        left: 100%;
        bottom: 100%;
      }
    }
  }

  &__form {
    height: auto;

    margin-top: 20px;
    padding: 40px 24px 28px;

    background: #f3e6a1;
    border: 1px solid #000000;
    border-radius: 13px;
    min-height: 40vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      margin-top: 25px;
      padding: 45px 40px;
    }

    @media screen and (min-width: 1200px) {
      min-width: 535px;
      width: 100%;
      max-width: 535px;
      min-height: 535px;
      max-height: 535px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto 0 0 0;
    padding-top: 50px;
  }

  &__next {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 48px;
    width: 142px;

    margin-left: auto;

    font-weight: 600;
    font-size: 14px;
    line-height: 10px;

    color: #ffffff;

    background: #ed0000;

    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 120px;

    transition: 0.4s;
    cursor: pointer;

    &::after {
      content: "";

      position: absolute;
      right: 0;
      top: 0;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      background-color: #ed0000;

      transition: 0.4s;
    }

    &::before {
      content: "";

      position: absolute;
      right: 0;
      top: 0;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      border: 1px solid transparent;

      transition: top 0.4s, right 0.4s;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        &::before {
          top: 10px;
          right: -5px;

          border-color: #000;
        }
      }
    }

    &:active {
      background-color: #fff;
      color: #ed0000;

      &::after {
        background-color: #fff;
      }

      &::before {
        top: 0;
        right: 0;
      }

      svg {
        path {
          fill: #ed0000;
        }
      }
    }

    &:disabled {
      color: #ed0000;
      background-color: transparent;

      opacity: 0.24;

      &::before,
      &::after {
        display: none;
      }

      svg {
        path {
          fill: #ed0000;
        }
      }
    }

    svg {
      position: relative;
      z-index: 1;

      max-width: 12px;

      margin-left: 5px;

      path {
        transition: 0.4s;
      }
    }

    p {
      position: relative;
      z-index: 1;
    }
  }

  &__prev {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 48px;

    font-weight: 600;
    font-size: 14px;
    line-height: 10px;

    color: #ed0000;

    transition: 0.4s;
    cursor: pointer;

    svg {
      max-width: 12px;

      margin-right: 5px;

      path {
        transition: 0.4s;
      }
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        color: #000;

        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }
}
</style>
