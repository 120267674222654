<template>
  <div class="test">
    <div class="test__container container">
      <section class="test__item test__item--0" v-if="getStage === 0">
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">
              Пройди тест — <br/><span>дізнайся про свій ризик</span>
            </h2>
            <p class="test__subtitle">
              тест на потенційну небезпеку зараження гепатитом С та В
              <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M4.63571 5.13755C4.24002 5.28153 3.97656 5.6586 3.97656 6.07975L3.97656 6.08171C3.97754 6.50384 4.24198 6.87993 4.63864 7.02293C13.8255 10.3373 19.579 19.9144 19.9854 29.6199L10.9797 29.6199C10.5742 29.6199 10.2079 29.8643 10.0532 30.2389C9.8955 30.615 9.98365 31.0455 10.2706 31.332L28.7042 49.7655C28.8922 49.9535 29.1469 50.0593 29.4133 50.0593C29.6797 50.0593 29.9343 49.9535 30.1224 49.7655L48.5657 31.3324C48.7576 31.1405 48.8595 30.8839 48.8595 30.6229C48.8595 30.4936 48.835 30.3633 48.7831 30.2394C48.6284 29.8643 48.2621 29.6199 47.8566 29.6199L39.0428 29.6199C38.513 15.7049 27.0274 4.54647 12.9855 4.54647C9.93174 4.54647 7.46719 4.10525 4.63571 5.13755Z"
                    fill="#F3E6A1"
                />
                <path
                    d="M1.75331 2.77745C1.3011 2.94199 0.999998 3.37294 0.999998 3.85425C0.999998 3.85537 0.999998 3.85537 0.999998 3.85649C1.00112 4.33892 1.30334 4.76874 1.75666 4.93216C12.256 8.71997 19.4242 18.5634 19.8887 29.6554L9.59645 29.6554C9.13305 29.6554 8.71442 29.9346 8.53757 30.3628C8.35735 30.7926 8.45809 31.2845 8.78606 31.6119L29.853 52.6788C30.0679 52.8938 30.3589 53.0146 30.6634 53.0146C30.9678 53.0146 31.2588 52.8938 31.4738 52.6788L52.5518 31.6125C52.7712 31.3931 52.8876 31.0998 52.8876 30.8015C52.8876 30.6538 52.8597 30.5049 52.8003 30.3633C52.6235 29.9346 52.2049 29.6554 51.7415 29.6554L41.6686 29.6554C41.063 13.7525 27.9367 0.999955 11.8888 0.999956C8.39877 0.999956 4.98929 1.59768 1.75331 2.77745Z"
                    stroke="#1E1E1E"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </p>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <p class="test__text">Ваш вік</p>
              <div class="test__field">
                <input
                    key="0"
                    type="radio"
                    class="test__input"
                    name="q0"
                    id="q0_0"
                    :checked="getAnswers.q0 && getAnswers.q0.includes(0)"
                    @change="pickOne('q0', 0)"
                />
                <label for="q0_0" class="test__label">
                  <p>менше 16 років</p>
                </label>

                <input
                    type="radio"
                    key="1"
                    class="test__input"
                    name="q0"
                    id="q0_1"
                    :checked="getAnswers.q0 && getAnswers.q0.includes(1)"
                    @change="pickOne('q0', 1)"
                />
                <label for="q0_1" class="test__label">
                  <p>16 і більше років</p>
                </label>
              </div>

              <p class="test__text">Ваша стать</p>
              <div class="test__field">
                <input
                    type="radio"
                    key="2"
                    class="test__input"
                    name="q0-1"
                    id="q0-1_0"
                    :checked="getAnswers.q0_1 && getAnswers.q0_1.includes(0)"
                    @change="pickOne('q0_1', 0)"
                />
                <label for="q0-1_0" class="test__label">
                  <p>Чоловік</p>
                </label>

                <input
                    type="radio"
                    key="3"
                    class="test__input"
                    name="q0-1"
                    id="q0-1_1"
                    :checked="getAnswers.q0_1 && getAnswers.q0_1.includes(1)"
                    @change="pickOne('q0_1', 1)"
                />
                <label for="q0-1_1" class="test__label">
                  <p>Жінка</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section class="test__item test__item--1" v-if="getStage === 1">
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">
              Оберіть зі списку тестування, які Ви проходили за останні 12
              місяців
            </h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div
                  v-if="getAnswers.q0_1 && getAnswers.q0_1.includes(1)"
                  class="test__field"
              >
                <input
                    type="radio"
                    key="4"
                    class="test__input"
                    id="q1_0"
                    :checked="getAnswers.q1 && getAnswers.q1.includes(0)"
                    @change="pickOne('q1', 0)"
                />
                <label for="q1_0" class="test__label">
                  <p>Ніколи не тестувалася на вірусні гепатити В, С та ВІЛ</p>
                </label>
              </div>

              <div v-else class="test__field">
                <input
                    type="radio"
                    key="4"
                    class="test__input"
                    id="q1_0"
                    :checked="getAnswers.q1 && getAnswers.q1.includes(0)"
                    @change="pickOne('q1', 0)"
                />
                <label for="q1_0" class="test__label">
                  <p>Ніколи не тестувався на вірусні гепатити В, С та ВІЛ</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="5"
                    class="test__input"
                    id="q1_1"
                    :checked="getAnswers.q1 && getAnswers.q1.includes(1)"
                    @change="pickOne('q1', 1)"
                />
                <label for="q1_1" class="test__label">
                  <p>Тільки вірусний гепатит&nbsp;С</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="6"
                    class="test__input"
                    id="q1_2"
                    :checked="getAnswers.q1 && getAnswers.q1.includes(2)"
                    @change="pickOne('q1', 2)"
                />
                <label for="q1_2" class="test__label">
                  <p>Тільки вірусний гепатит&nbsp;В</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="7"
                    class="test__input"
                    id="q1_3"
                    :checked="getAnswers.q1 && getAnswers.q1.includes(3)"
                    @change="pickOne('q1', 3)"
                />
                <label for="q1_3" class="test__label">
                  <p>Тільки ВІЛ-інфекція</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="8"
                    class="test__input"
                    id="q1_4"
                    :checked="getAnswers.q1 && getAnswers.q1.includes(4)"
                    @change="pickOne('q1', 4)"
                />
                <label for="q1_4" class="test__label">
                  <p>Вірусні гепатити В та С</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="9"
                    class="test__input"
                    id="q1_5"
                    :checked="getAnswers.q1 && getAnswers.q1.includes(5)"
                    @change="pickOne('q1', 5)"
                />
                <label for="q1_5" class="test__label">
                  <p>Вірусні гепатити В та С, ВІЛ-інфекція</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section class="test__item test__item--2" v-if="getStage === 2">
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">Ви вакциновані від вірусного гепатиту?</h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div class="test__field">
                <input
                    type="radio"
                    key="10"
                    class="test__input"
                    name="q2"
                    id="q2_0"
                    :checked="getAnswers.q2 && getAnswers.q2.includes(0)"
                    @change="pickOne('q2', 0)"
                />
                <label for="q2_0" class="test__label">
                  <p>Ні</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="11"
                    class="test__input"
                    name="q2"
                    id="q2_1"
                    :checked="getAnswers.q2 && getAnswers.q2.includes(1)"
                    @change="pickOne('q2', 1)"
                />
                <label for="q2_1" class="test__label">
                  <p>Не знаю</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="12"
                    class="test__input"
                    name="q2"
                    id="q2_2"
                    :checked="getAnswers.q2 && getAnswers.q2.includes(2)"
                    @change="pickOne('q2', 2)"
                />
                <label for="q2_2" class="test__label">
                  <p>Так</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section
          class="test__item test__item--3-1"
          v-if="getStage === 3 && getAnswers.q0_1 && getAnswers.q0_1.includes(1)"
      >
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">Ви вагітна?</h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div class="test__field">
                <input
                    type="radio"
                    key="13"
                    class="test__input"
                    name="q3"
                    id="q3_0-1"
                    :checked="getAnswers.q3_1 && getAnswers.q3_1.includes(0)"
                    @change="pickOne('q3_1', 0)"
                />
                <label for="q3_0-1" class="test__label">
                  <p>Ні</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="14"
                    class="test__input"
                    name="q3"
                    id="q3_1-1"
                    :checked="getAnswers.q3_1 && getAnswers.q3_1.includes(1)"
                    @change="pickOne('q3_1', 1)"
                />
                <label for="q3_1-1" class="test__label">
                  <p>Так</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section
          class="test__item test__item--3"
          v-if="
          getAnswers.q0_1 && getAnswers.q0_1.includes(1)
            ? getStage === 4
            : getStage === 3
        "
      >
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">
              Оберіть ВСІ процедури, якими Ви користувалися хоча б раз у житті
              або проходите постійно?
            </h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div class="test__field">
                <input
                    type="checkbox"
                    key="15"
                    class="test__input"
                    id="q3_0"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(0)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 0)"
                />
                <label for="q3_0" class="test__label">
                  <p>Татуювання</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="checkbox"
                    key="16"
                    class="test__input"
                    id="q3_1"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(1)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 1)"
                />
                <label for="q3_1" class="test__label">
                  <p>Пірсинг</p>
                </label>
              </div>

              <div
                  class="test__field"
                  v-if="getAnswers.q0_1 && getAnswers.q0_1.includes(1)"
              >
                <input
                    type="checkbox"
                    key="17"
                    class="test__input"
                    id="q3_2"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(2)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 2)"
                />
                <label for="q3_2" class="test__label">
                  <p>Перманентний макіяж</p>
                </label>
              </div>

              <div v-else class="test__field">
                <input
                    type="checkbox"
                    key="17"
                    class="test__input"
                    id="q3_2"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(2)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 2)"
                />
                <label for="q3_2" class="test__label">
                  <p>Гоління та догляд за бородою чи вусами в барбершопах</p>
                </label>
              </div>

              <div
                  class="test__field"
                  v-if="getAnswers.q0_1 && getAnswers.q0_1.includes(1)"
              >
                <input
                    type="checkbox"
                    key="18"
                    class="test__input"
                    id="q3_3"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(3)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 3)"
                />
                <label for="q3_3" class="test__label">
                  <p>Педикюр в косметологічних салонах</p>
                </label>
              </div>

              <div class="test__field" v-else>
                <input
                    type="checkbox"
                    key="18"
                    class="test__input"
                    id="q3_3"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(3)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 3)"
                />
                <label for="q3_3" class="test__label">
                  <p>Тренування з боксу та інших видів бойових мистецтв</p>
                </label>
              </div>

              <div
                  v-if="getAnswers.q0_1 && getAnswers.q0_1.includes(1)"
                  class="test__field"
              >
                <input
                    type="checkbox"
                    key="19"
                    class="test__input"
                    id="q3_4"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(4)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 4)"
                />
                <label for="q3_4" class="test__label">
                  <p>Манікюр в косметологічних салонах</p>
                </label>
              </div>

              <div v-else class="test__field">
                <input
                    type="checkbox"
                    key="19"
                    class="test__input"
                    id="q3_4"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(4)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 4)"
                />
                <label for="q3_4" class="test__label">
                  <p>Манікюр, педикюр в косметологічних салонах</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="checkbox"
                    key="20"
                    class="test__input"
                    id="q3_5"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(5)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 5)"
                />
                <label for="q3_5" class="test__label">
                  <p>Процедури ін'єкційної косметології</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="checkbox"
                    key="21"
                    class="test__input"
                    id="q3_6"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(6)"
                    :disabled="getAnswers.q3 && getAnswers.q3.includes(7)"
                    @change="pickMultiple('q3', 6)"
                />
                <label for="q3_6" class="test__label">
                  <p>Стоматологічне лікування</p>
                </label>
              </div>

              <div
                  v-if="getAnswers.q0_1 && getAnswers.q0_1.includes(1)"
                  class="test__field"
              >
                <input
                    type="checkbox"
                    key="22"
                    class="test__input"
                    id="q3_7"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(7)"
                    :disabled="
                    getAnswers.q3 &&
                    getAnswers.q3.length > 0 &&
                    !getAnswers.q3.includes(7)
                  "
                    @change="pickMultiple('q3', 7)"
                />
                <label for="q3_7" class="test__label">
                  <p>Не користувалася</p>
                </label>
              </div>

              <div class="test__field" v-else>
                <input
                    type="checkbox"
                    key="22"
                    class="test__input"
                    id="q3_7"
                    :checked="getAnswers.q3 && getAnswers.q3.includes(7)"
                    :disabled="
                    getAnswers.q3 &&
                    getAnswers.q3.length > 0 &&
                    !getAnswers.q3.includes(7)
                  "
                    @change="pickMultiple('q3', 7)"
                />
                <label for="q3_7" class="test__label">
                  <p>Не користувався</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section
          class="test__item test__item--4"
          v-if="
          getAnswers.q0_1 && getAnswers.q0_1.includes(1)
            ? getStage === 5
            : getStage === 4
        "
      >
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">
              Оберіть ВСі пункти, якщо вони стосуються вас
            </h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div class="test__field">
                <input
                    type="checkbox"
                    key="22"
                    class="test__input"
                    id="q4_0"
                    :checked="getAnswers.q4 && getAnswers.q4.includes(0)"
                    :disabled="getAnswers.q4 && getAnswers.q4.includes(3)"
                    @change="pickMultiple('q4', 0)"
                />
                <label for="q4_0" class="test__label">
                  <p>
                    Ви перебуваєте на гемодіалізі або проходили процедуру раніше
                  </p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="checkbox"
                    key="23"
                    class="test__input"
                    id="q4_1"
                    :checked="getAnswers.q4 && getAnswers.q4.includes(1)"
                    :disabled="getAnswers.q4 && getAnswers.q4.includes(3)"
                    @change="pickMultiple('q4', 1)"
                />
                <label for="q4_1" class="test__label">
                  <p>
                    Ви знаходитесь на імуносупресивній терапії, включаючи
                    хіміотерапію та імуносупресію, пов'язану з трансплантацією
                    органів
                  </p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="checkbox"
                    key="24"
                    class="test__input"
                    id="q4_2"
                    :checked="getAnswers.q4 && getAnswers.q4.includes(2)"
                    :disabled="getAnswers.q4 && getAnswers.q4.includes(3)"
                    @change="pickMultiple('q4', 2)"
                />
                <label for="q4_2" class="test__label">
                  <p>
                    Ви на імуносупресивному лікуванні через ревматологічні або
                    гастроентерологічні захворювання
                  </p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="checkbox"
                    key="25"
                    class="test__input"
                    id="q4_3"
                    :checked="getAnswers.q4 && getAnswers.q4.includes(3)"
                    :disabled="
                    getAnswers.q4 &&
                    getAnswers.q4.length > 0 &&
                    !getAnswers.q4.includes(3)
                  "
                    @change="pickMultiple('q4', 3)"
                />
                <label for="q4_3" class="test__label">
                  <p>Мене не стосується нічого з вищеперерахованого</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section
          class="test__item test__item--5"
          v-if="
          getAnswers.q0_1 && getAnswers.q0_1.includes(1)
            ? getStage === 6
            : getStage === 5
        "
      >
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">
              чи пов'язана Ваша професійна діяльність з однією Із цИх галузей?
            </h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div class="test__field">
                <input
                    type="radio"
                    key="26"
                    class="test__input"
                    id="q5_0"
                    :checked="getAnswers.q5 && getAnswers.q5.includes(0)"
                    @change="pickOne('q5', 0)"
                />
                <label for="q5_0" class="test__label">
                  <p>Медична галузь</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="27"
                    class="test__input"
                    id="q5_1"
                    :checked="getAnswers.q5 && getAnswers.q5.includes(1)"
                    @change="pickOne('q5', 1)"
                />
                <label for="q5_1" class="test__label">
                  <p>Служба надзвичайних ситуацій</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="28"
                    class="test__input"
                    id="q5_2"
                    :checked="getAnswers.q5 && getAnswers.q5.includes(2)"
                    @change="pickOne('q5', 2)"
                />
                <label for="q5_2" class="test__label">
                  <p>Правоохоронні органи</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="29"
                    class="test__input"
                    id="q5_3"
                    :checked="getAnswers.q5 && getAnswers.q5.includes(3)"
                    @change="pickOne('q5', 3)"
                />
                <label for="q5_3" class="test__label">
                  <p>
                    Професійна діяльність не стосується вищезгаданих галузей
                  </p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section
          class="test__item test__item--6"
          v-if="
          getAnswers.q0_1 && getAnswers.q0_1.includes(1)
            ? getStage === 7
            : getStage === 6
        "
      >
        <TestItem>
          <template v-slot:description>
            <h2 class="test__title">
              чи є У Вашому близькому оточенні хтось із підтвердженим діагнозом
              вірусного гепатиту В або С?
            </h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div class="test__field">
                <input
                    type="radio"
                    key="30"
                    class="test__input"
                    name="q6"
                    id="q6_0"
                    :checked="getAnswers.q6 && getAnswers.q6.includes(0)"
                    @change="pickOne('q6', 0)"
                />
                <label for="q6_0" class="test__label">
                  <p>Так</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="31"
                    class="test__input"
                    name="q6"
                    id="q6_1"
                    :checked="getAnswers.q6 && getAnswers.q6.includes(1)"
                    @change="pickOne('q6', 1)"
                />
                <label for="q6_1" class="test__label">
                  <p>Ні</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>

      <section
          class="test__item test__item--7"
          :class="{ women: getAnswers.q0_1.includes(1) }"
          v-if="
          getAnswers.q0_1 && getAnswers.q0_1.includes(1)
            ? getStage === 8
            : getStage === 7
        "
      >
        <TestItem>
          <template v-slot:description>
            <h2 v-if="getAnswers.q0_1.includes(1)" class="test__title">
              Ви мали/маєте велику кількість статевих партнерів?
            </h2>
            <h2 v-else class="test__title">
              Ви мали/маєте велику кількість статевих партнерів?
            </h2>
          </template>
          <template v-slot:test>
            <div class="test__form-container">
              <div class="test__field">
                <input
                    type="radio"
                    key="32"
                    class="test__input"
                    name="q7"
                    id="q7_0"
                    :checked="getAnswers.q7 && getAnswers.q7.includes(0)"
                    @change="pickOne('q7', 0)"
                />
                <label for="q7_0" class="test__label">
                  <p>Так</p>
                </label>
              </div>

              <div class="test__field">
                <input
                    type="radio"
                    key="33"
                    class="test__input"
                    name="q7"
                    id="q7_1"
                    :checked="getAnswers.q7 && getAnswers.q7.includes(1)"
                    @change="pickOne('q7', 1)"
                />
                <label for="q7_1" class="test__label">
                  <p>Ні</p>
                </label>
              </div>
            </div>
          </template>
        </TestItem>
      </section>
    </div>
  </div>
</template>

<script>
import TestItem from "@/components/TestItem";

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "Test",
  components: {
    TestItem,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["getStage", "getStageBar", "getAnswers", "getResult"]),
  },

  mounted() {
    window.scroll(0, 0)
    this.clearTest()
  },
  methods: {
    ...mapActions(["clearTest"]),
    ...mapMutations([
      "setStage",
      "setStageBar",
      "setAnswers",
      "setIsStageEnable",
    ]),
    pickMultiple(name, value) {
      const answer = this.getAnswers[name];

      if (answer) {
        if (answer.includes(value)) {
          this.setAnswers({
            name,
            value: [...answer.filter((item) => item !== value)],
          });

          if (!this.getAnswers[name].length) {
            this.setIsStageEnable(false);
          }
        } else {
          this.setAnswers({
            name,
            value: [...answer, value],
          });

          this.setIsStageEnable(true);
        }
      } else {
        this.setAnswers({
          name,
          value: [value],
        });

        this.setIsStageEnable(true);
      }

    },
    pickOne(name, value) {
      this.setAnswers({
        name,
        value: [value],
      });
      if (name === "q0_1" || name === "q0") {
        if (Object.keys(this.getAnswers).length === 2) {
          this.setIsStageEnable(true);
        }
      } else {
        this.setIsStageEnable(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  min-height: 100vh;
  height: auto;
  padding-top: 100px;
  padding-bottom: 20px;

  background-color: #c83b26;

  @media screen and (min-width: 1200px) {
    padding-bottom: 100px;
  }

  &__container {
    @media screen and (min-width: 768px) {
      padding: 0 100px;
      //height: calc(100vh - 20px);
    }

    @media screen and (min-width: 1200px) {
      //height: calc(100vh - 200px);
    }
  }

  &__item {
    height: 100%;
  }

  &__title {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #f3e6a1;

    @media screen and (min-width: 768px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media screen and (min-width: 1200px) {
      display: none;
    }

    span {
      color: #fff;
    }
  }

  &__subtitle {
    position: relative;

    padding-right: 10%;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;

    color: #ffffff;

    @media screen and (min-width: 768px) {
      margin-top: 8px;
    }

    @media screen and (min-width: 1200px) {
      display: none;
    }

    svg {
      position: absolute;
      bottom: 0;
      right: 0;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  &__text {
    margin-bottom: 20px;

    font-family: "Oswald";
    font-size: 24px;
    line-height: 20px;

    color: #000000;

    @media screen and (min-width: 768px) {
      font-size: 28px;
      line-height: 33px;
    }
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .test__item--0 .test__field {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 70px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__label {
    position: relative;

    display: flex;
    align-items: center;

    width: 100%;

    padding: 8px 8px 8px 38px;

    background: #ffffff;
    border-radius: 27px;
    border: 1px solid transparent;

    font-size: 14px;
    line-height: 20px;

    cursor: pointer;
    transition: 0.4s;

    @media screen and (min-width: 768px) {
      height: 48px;
    }

    &::before {
      content: "";

      position: absolute;
      left: 8px;
      top: 50%;

      transform: translateY(-50%);

      width: 20px;
      height: 20px;

      opacity: 0.24;

      border: 2px solid #000000;
      border-radius: 50%;

      transition: 0.4s;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        border: 1px solid #000;
      }
    }

    &:nth-of-type(2) {
      margin-left: 20px;
    }
  }

  &__input {
    display: none;

    &:checked + .test__label {
      color: #c83b26;

      &::before {
        border: 6px solid #c83b26;
        opacity: 1;
      }
    }

    &:disabled + .test__label {
      opacity: 0.3;
    }
  }
}
</style>
